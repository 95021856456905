//*******************************************************************************************************************
//File Name          :   footer.scss
//Function           :   footer sass styles
//Created By         :   
//Created on         :   
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//24-Mar-2025    CV          (HDT#:787332)   Modified position styles to avoid visual glitch.
//*******************************************************************************************************************
@import "../../themes/generated/variables.base.scss";

$base-text-color: rgba(156, 144, 144, 0.77);
.footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: #fff;
  background-color: #271185;
  border: 1px solid #271185;
  padding: 8px 0;
  font-weight: 500;
  position: static;
    bottom: 0;
    right: 0;
    left: 0;
  //width: 100%;
  justify-items: flex-start;
  align-items: center;
  z-index: 10;
}
