//*******************************************************************************************************************
//File Name          :   dx-styles.scss
//Function           :   General/global styling and DevExpress style overrides
//Created By         :   
//Created on         :   
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//6-Feb-2025    CV          (HDT#:778891)   Modification Related to DOIMaintenance grid styles.
//24-Mar-2025   CV          (HDT#:787332)   Modified styles to make checkboxes padding smaller.
//*******************************************************************************************************************


@import "./themes/generated/variables.base.scss";
$base-accent: #271185;
$base-text-color: rgba(156, 144, 144, 0.77);
$base-bg: #fff;
$base-border-color: #e0e0e0;
$base-border-radius: 4px;
.app {
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  max-height: 1320px;
  overflow-y: hidden;
}
.data-grid-box > div{
  margin-bottom: 50px !important;
}

.app .content {
  line-height: .5;

  h2 {
    color: $base-accent !important;
    font-size: 30px !important;
    font-weight: 500 !important;
  }
  h3{
    color: $base-accent;
    font-size: 16px;
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 10px;
    font-weight: 500;
    margin-left: 40px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
}
.app .content-block-footer {
  //margin-top: 10px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;
  max-height: 60px !important;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;
/*
html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
  // overflow-y: hidden;
}
*/
#root {
  height: 100dvh;
}

* {
  box-sizing: border-box;
}
/*Fix styling over the devextreme theme*/
/*Module: DOI Maintenance*/
.dx-fileuploader-content-doi-maintenance .dx-fileuploader-content{
         min-width: 370px;
    width: 400px !important;
  }
  
  .doi-hint-container {
    width: auto;
    max-width: 100%;
    padding: 30px 0px 25px 0px;
    overflow: visible;
    overflow: hidden;
  }
  .doi-hint{
    overflow: hidden;
    word-break: break-word;
    color: #271185;
    font-size: 14px;
    font-weight: 500;
  }
  
  .item-select-file-button{
    width: 120px !important;
    min-width: 110px;
    position: relative;
      right: 0%;
  }
  .item-hint-text{
    display: flex;
    width: auto !important; 
    min-width: 80px !important;
    max-width: 90vw; 
    position: relative;
    right: 0%;
    padding: 0px 0px !important;
    text-align: start;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
  }
  /*End Module: DOI Maintenance*/
//Start checkbox size fix
//This might affect other components, it needs to be check thoroughly, this overwrites the theme base styles
.dx-datagrid-container .dx-editor-with-menu .dx-menu-item-content .dx-icon {
	line-height: 0px !important;
}
.dx-datagrid .dx-row > td:has(div.dx-checkbox) {
  padding: 0rem 1rem 0rem 1rem !important;
  line-height: 0px !important;
}
//End checkbox size fix