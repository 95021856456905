//*******************************************************************************************************************
//File Name          :   customerGroupMaster.scss
//Function           :   Cusstomer group frontend scss stylesheets for module for FGSSMaster
//Created By         :   Carlo Valenzuela
//Created on         :   March 14th 2025
 
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//14-Mar-2025    CV          (HDT#:775711)   Created the file and added styles for datagrid adjustment.
//*******************************************************************************************************************
.customer-group-master-datagrid{
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 1.4rem 0rem 1.4rem;
}